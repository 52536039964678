<script>
	import Base from '@backend/Base.vue';
    import BOGen from '@helper/BOGen';
    

	export default {
		name: "Received",
		extends: Base,
		data() {
			return {
				Name: "Received",
				mrProject:[],
                detailTrx:[{"ac_fullname":""}],
                detailTransfer:[],
                note:null,
                link_download:null,
                input:{}
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
			this.filter.status = ""
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
		},
        methods: {
            formatRupiah(value) {
                if (isNaN(value)) return value;
                value = parseFloat(value);
                return value.toLocaleString('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 0
                });
            },
            viewDetail(v) {
                BOGen.apirest('/' + this.Name, {
					data: v.ats_trx_no,
					type: 'getDetail'
				}, (err, resp) => {
                    this.detailTrx = resp['data']
                    this.note = v.ats_trx_note
                    $('#detailOrder').modal('show')
				}, "POST");
            },
            
        },
        
	};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row">
								<div class="col-sm-2">
									<h5 class="card-title">{{ObjectName}} List</h5>
								</div>
                                <div class="col-sm-2">
                                    <DatePicker  @input="search()" v-model="filter.start_date" placeholder="Start Date"></DatePicker>
                                </div>
                                <div class="col-sm-2">
                                    <DatePicker  @input="search()" v-model="filter.end_date" placeholder="End Date"></DatePicker>
                                </div>
								<div class="col-sm-3">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" class="form-control" placeholder="Search...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-1">
									<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
								</div>
								<div class="col-sm-2 text-right">
                                    <a :href="link_download" target="_blank" style="color:#fff" class="btn btn-success btn-block btn_export">Export Excel</a>
                                </div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
                                    <th>#</th>
                                    <th>Nomor Transaksi</th>
                                    <th>Total Harga</th>
                                    <th>Tanggal Pembelian</th>
                                    <th>Jenis Cargo</th>
                                    <th>No Resi</th>
                                    <th>last Status</th>
                                    <th class="text-center" width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{v.ats_trx_no}}</td>
									<td>{{formatRupiah(v.ats_harga_total)}}</td>
									<td>{{(v.ats_trx_date||"").dates("format")}}</td>
									<td>{{v.ats_cargo}}</td>
                                    <td>{{ v.ats_no_resi ? v.ats_no_resi : '-' }}</td>
                                    <td>
                                        <StatusLabel :status="v.status" :type="'delivery_proccess'"></StatusLabel>
                                    </td>
                                    <td class="btn-action text-center">
                                        <a href="javascript:;" @click="viewDetail(v)" class="icon_action view mr-2" title="View Detail" v-tooltip="'view Detail'"><i class="fa fa-eye"></i></a>
                                    </td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										{{ note }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="card-body">
						<Pagination class="mb-0 justify-content-end" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
					</div>
				</div>
			</div>
		</div>

        <!-- Modal Detail Order -->
        <div class="modal fade" id="detailOrder" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Detail Order</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                    <div class="modal-body">
                        <table class="table table-striped table-bordered">  
                            <thead>
                                <tr>
                                    <th colspan="7">Product</th>
                                </tr>
                                <tr> 
                                    <th>Product Id</th>
                                    <th>Product Name</th>
                                    <th>Color</th>
                                    <th>Size</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(val,key) in detailTrx">
                                    <td>{{ val.ap_code }}</td>
                                    <td>{{ val.ap_name }}</td>
                                    <td>{{ val.at_trx_color }}</td>
                                    <td>{{ val.at_trx_size }}</td>
                                    <td>{{ val.at_trx_jml_product }}</td>
                                    <td>{{ formatRupiah(val.ap_admin_price) }}</td>
                                    <td>{{ formatRupiah(val.at_trx_subtotal) }}</td>
                                </tr>
                            </tbody>
                        </table>

                        <table class="table table-striped table-bordered">  
                            <thead>
                                <tr>
                                    <th colspan="2">Customer</th>
                                </tr>
                                <tr> 
                                    <th>Name</th>
                                    <td>{{detailTrx[0].ac_fullname}}</td>
                                </tr>
                                <tr> 
                                    <th>Phone</th>
                                    <td>{{detailTrx[0].ac_phone}}</td>
                                </tr>
                                <tr> 
                                    <th>Email</th>
                                    <td>{{detailTrx[0].ac_email}}</td>
                                </tr>
                                <tr> 
                                    <th>Address</th>
                                    <td>{{detailTrx[0].ac_address}}
                                        <br />
                                        <small class="text-info">
                                            {{ detailTrx[0].ac_kel+', '+detailTrx[0].ac_kec+', '+detailTrx[0].ac_kab+', '+detailTrx[0].ac_prov }}
                                        </small>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Note</th>
                                    <td></td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <!-- <button type="submit" class="btn btn-info">Save changes</button> -->
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>